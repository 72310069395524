<template>
  <div>
    <div class="mb-6">
      <span class="d-block text-xl-h4 text-lg-h4 text-md-h4 text-sm-h5 text-h6 font-weight-black black--text">
        CBT (Computers Based Test)
      </span>
      <small class="text-subtitle-2">Ujian CBT</small>
    </div>
    <v-row>
      <v-col
        lg="4"
        md="8"
        sm="12"
      >
        <v-text-field
          v-model="search"
          outlined
          :append-icon="icons.mdiMagnify"
          dense
          background-color="white"
          placeholder="Cari"
          hide-details="none"
          @change="searchHandler($event)"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row
      v-if="!isLoadingCard"
      class="match-height my-6"
    >
      <v-col
        v-for="cbtItem,index in cbt"
        :key="index"
        cols="12"
        sm="6"
        md="4"
        lg="4"
        xl="4"
      >
        <v-card class="overflow-hidden">
          <div
            class="pa-2 position-absolute"
            style="right: 0;z-index: 1"
          >
            <v-spacer></v-spacer>
            <v-menu
              offset-y
              nudge-bottom="5"
              nudge-left="60"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="white"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="20">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item :to="{ name: 'cbt-edit-school',params: {uuid: cbtItem.uuid }}">
                  <v-list-item-title>
                    <div class="d-flex">
                      <div>
                        <v-img
                          width="16"
                          src="@/assets/icons/edit.svg"
                        ></v-img>
                      </div>

                      <small class="ml-2">Edit</small>
                    </div>
                  </v-list-item-title>
                </v-list-item>
                <a @click="confirmDestroy(cbtItem.uuid)">
                  <v-list-item>
                    <v-list-item-title>
                      <div class="d-flex btn-delete">
                        <div>
                          <v-img
                            width="16"
                            src="@/assets/icons/trash-red.svg"
                          ></v-img>
                        </div>

                        <small class="ml-2">Hapus</small>
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                </a>
              </v-list>
            </v-menu>
          </div>
          <!-- <v-card
            height="150"
            color="#D14362"
          >
            <div
              class="pa-2 position-absolute"
              style="right: 0;z-index: 1"
            >
              <v-spacer></v-spacer>
              <v-menu
                offset-y
                nudge-bottom="5"
                nudge-left="60"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="white"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="20">
                      {{ icons.mdiDotsVertical }}
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item :to="{ name: 'cbt-edit-school',params: {uuid: cbtItem.uuid }}">
                    <v-list-item-title>
                      <div class="d-flex">
                        <div>
                          <v-img
                            width="16"
                            src="@/assets/icons/edit.svg"
                          ></v-img>
                        </div>

                        <small class="ml-2">Edit</small>
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                  <a @click="confirmDestroy(cbtItem.uuid)">
                    <v-list-item>
                      <v-list-item-title>
                        <div class="d-flex btn-delete">
                          <div>
                            <v-img
                              width="16"
                              src="@/assets/icons/trash-red.svg"
                            ></v-img>
                          </div>

                          <small class="ml-2">Hapus</small>
                        </div>
                      </v-list-item-title>
                    </v-list-item>
                  </a>
                </v-list>
              </v-menu>
            </div>
            <div class="d-flex justify-center">
              <div>
                <v-img
                  src="@/assets/images/vector/subject.svg"
                  width="100%"
                ></v-img>
              </div>
            </div>
          </v-card> -->
          <div
            v-if="cbtItem.thumbnail"
            class="position-relative"
          >
            <v-img
              height="150"
              :src="cbtItem.thumbnail"
            ></v-img>
            <v-overlay
              :absolute="true"
              z-index="0"
              opacity="0.25"
              color="#001A33"
            ></v-overlay>
          </div>
          <v-img
            v-else
            height="150"
            src="@/assets/images/vector/cbt.svg"
            width="100%"
          ></v-img>
          <v-card-text
            class="py-4"
          >
            <div class="mb-4">
              <h2 class="font-weight-bold mb-4 black--text">
                {{ cbtItem.name }}
              </h2>
            </div>
            <div class="d-flex mb-2">
              <div class="d-flex align-center mb-2">
                <v-img
                  :src="icons.iconUser"
                  width="20"
                ></v-img>
                <span class="ml-4">Admin</span>
              </div>
            </div>
            <div class="d-flex mb-2">
              <div class="d-flex align-center mb-2">
                <v-img
                  :src="icons.iconCalendar"
                  width="20"
                ></v-img> <span class="ml-4">{{ cbtItem.start_date }}</span>
              </div>
            </div>
            <div class="d-flex mb-2">
              <div class="d-flex align-center mb-2">
                <v-img
                  :src="icons.iconClock"
                  width="20"
                ></v-img> <span class="ml-4">{{ cbtItem.duration }} Menit</span>
              </div>
            </div>
            <div class="d-flex mb-2">
              <div class="d-flex align-center mb-2">
                <v-img
                  :src="icons.iconDocument"
                  width="20"
                ></v-img> <span class="ml-4">{{ cbtItem.question.length }} Soal</span>
              </div>
            </div>
            <div class="d-flex align-center">
              <span class="me-4">Token : </span> <h2>{{ cbtItem.token }}</h2>
            </div>
            <!-- <v-divider></v-divider>
              <div class="d-flex justify-space-between align-center">
                <div>
                  <span>ddd</span>
                </div>
                <div>
                  <div
                    class="d-flex"
                    style=""
                  >
                    <v-avatar
                      style="margin-left:-15px"
                    >
                      <v-img
                        src=""
                        width="100%"
                        height="100%"
                        style="border: 3px solid #FFF"
                      ></v-img>
                    </v-avatar>
                  </div>
                </div>
              </div> -->
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-btn
              color="primary"
              x-large
              width="100%"
              :to="{ name: 'cbt-and-result-school',params: { uuid: cbtItem.uuid }}"
            >
              Lihat Cbt
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-else
      class="my-6"
    >
      <v-col
        v-for="i,index in 3"
        :key="index"
        md="4"
      >
        <v-skeleton-loader
          type="card-avatar,article, actions"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <div class="text-center">
      <div v-if="isEmpty">
        <div class="d-flex justify-center">
          <div>
            <v-img
              src="@/assets/images/vector/cbt-empty.svg"
            ></v-img>
            <h3 class="font-weight-bold text--black my-6">
              Belum ada CBT
            </h3>
          </div>
        </div>
      </div>
      <div
        v-if="cbt.length"
        class="my-6"
      >
        <v-pagination
          v-model="page"
          :length="totalPages"
          total-visible="7"
          @change="paginationHandler"
        ></v-pagination>
      </div>
      <v-btn
        color="primary"
        x-large
        :to="{name:'cbt-add-school'}"
      >
        Tambah CBT
      </v-btn>
    </div>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus CBT"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiMagnify } from '@mdi/js'
import iconCalendar from '@/assets/icons/calendar.svg'
import iconClock from '@/assets/icons/clock.svg'
import iconDocument from '@/assets/icons/document-text.svg'
import iconUser from '@/assets/icons/user.svg'
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'

export default {
  name: 'Cbt',
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      icons: {
        iconDocument,
        iconUser,
        iconClock,
        iconCalendar,
        mdiDotsVertical,
        mdiMagnify,
      },
      service: 'cbt',
      cbt: [],
      isLoadingButton: false,
      isDisabledButton: false,
      confirmDialog: false,
      school: [],
      school_uuid: '',
      user_uuid: '',
      search: '',
      page: 1,
      totalPages: 0,
      totalItems: 0,
      isEmpty: false,
      isLoadingCard: true,
    }
  },
  watch: {
    page: {
      handler() {
        this.getCbt()
      },
    },
  },
  async mounted() {
    this.getSchoolUuid()
    await this.getCbt()
    this.isLoadingCard = false
    localStorage.removeItem('cbt')
  },
  methods: {
    async getCbt(params = {}) {
      this.isLoadingCard = true
      await this.$services.ApiServices.list(this.service, {
        ...params,
        type_uuid: this.school_uuid,
        per_page: '3',
        page: this.page,
      }).then(
        ({ data }) => {
          this.cbt = data.data
          this.isEmpty = false
          if (this.cbt.length < 1) {
            this.isEmpty = true
          }
          this.totalPages = data.meta.last_page
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingCard = false
    },
    async paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid
          this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school forum',
            color: 'error',
          })

          return
        }
        this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid
        this.school_uuid = el.school.uuid
      })

      return true
    },
    confirmDestroy(uuid) {
      this.cbt.uuid = uuid
      this.confirmDialog = true
    },
    async destroy() {
      this.isLoadingButton = true
      this.isDisabledButton = true
      await this.$services.ApiServices.destroy(this.service, this.cbt.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => console.error(err),
      )
      this.isLoadingButton = false
      this.isDisabledButton = false
      this.confirmDialog = false
      this.getCbt()
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
.btn-delete {
  color: #e30000 !important;
}
</style>
